import chroma from "chroma-js";
import Texture from "../types/Texture";

const recolourTexture = ({
  texture,
  colour,
}: {
  texture: Texture;
  colour: string;
}) => {
  const rgb = chroma(colour).rgb();
  const canvas = document.createElement("canvas");
  canvas.width = texture.width;
  canvas.height = texture.height;

  const context = canvas.getContext("2d")!;
  context.drawImage(texture, 0, 0);

  const imageData = context.getImageData(0, 0, texture.width, texture.height);

  for (let i = 0; i < imageData.data.length; i += 4) {
    const alpha = imageData.data[i + 3];
    if (alpha === 0) {
      continue;
    }
    imageData.data[i] = rgb[0];
    imageData.data[i + 1] = rgb[1];
    imageData.data[i + 2] = rgb[2];
  }

  context.putImageData(imageData, 0, 0);

  return canvas;
};

export default recolourTexture;

import { ReactNode } from "react"

const ContestContainer = ({ children }: { children: ReactNode }) => {
    return (
        <div className="p-3 bg-[#faf8ef] border-4 border-pink-300 shadow shadow-pink-400 rounded-xl">
            {children}
        </div>
    )
}

export default ContestContainer
import classNames from "classnames";
import { useAtom } from "jotai";
import { ReactNode, useEffect, useRef } from "react";
import { scrollStateAtom } from "./editorAtoms";

const BottomModal = ({ name, open, onClose, children }: { name: string, open: boolean, onClose: () => void, children: ReactNode }) => {
    const [scrollState, setScrollState] = useAtom(scrollStateAtom)

    const ref = useRef<HTMLDivElement>(null);
    const scrollLeft = useRef(scrollState[name] || 0);

    useEffect(() => {
        const element = ref.current

        if (element) {
            element.scrollLeft = scrollLeft.current

            const handleScrollChange = () => {
                setScrollState((prev) => ({ ...prev, [name]: element.scrollLeft }))
                scrollLeft.current = element.scrollLeft
            }

            element.addEventListener("scroll", handleScrollChange)
            return () => element.removeEventListener("scroll", handleScrollChange)
        }
    }, [name, setScrollState, ref, open])

    if (!open) {
        return
    }

    return (
        <div onClick={onClose} className={classNames("fixed inset-0 bg-gray-50 bg-transparent")}>
            <div className={"fixed bottom-0 left-0 right-0 animate-in slide-in-from-bottom-full duration-200"}>
                <div ref={ref} className="flex p-5 gap-3 max-w-full overflow-scroll no-scrollbar border rounded-t-xl shadow bg-white dark:bg-gray-700 dark:border-gray-800">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default BottomModal;
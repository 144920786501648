import { useEffect } from "react";

// Features:
// sends onGesture when only one finger is pressed and moved
// does not send onGesture when more than one finger is pressed and moved
// when a second finger is put down, it sends onGestureCancel and resets
// when the first (or any) finger is lifted, it sends onGestureEnd

const calculateMouseLocation = (
  canvas: HTMLCanvasElement,
  location: { x: number; y: number }
) => {
  const boundingBox = canvas.getBoundingClientRect();
  return {
    x: Math.floor(
      ((location.x - boundingBox.left) / boundingBox.width) * canvas.width
    ),
    y: Math.floor(
      ((location.y - boundingBox.top) / boundingBox.height) * canvas.height
    ),
  };
};

const useOneFinger = ({
  element,
  onGesture,
  onGestureEnd,
  onGestureCancel,
}: {
  element: HTMLCanvasElement | null;
  onGesture?: (
    start: { x: number; y: number },
    end: { x: number; y: number }
  ) => void;
  onGestureEnd?: (end: { x: number; y: number }) => void;
  onGestureCancel?: () => void;
}) => {
  useEffect(() => {
    let downEvent: PointerEvent | null = null;

    if (!element) {
      return;
    }

    const handlePointerDown = (event: PointerEvent) => {
      if (downEvent) {
        onGestureCancel?.();
        downEvent = null;
      } else {
        downEvent = event;
      }
    };

    const handlePointerMove = (event: PointerEvent) => {
      if (downEvent) {
        onGesture?.(
          calculateMouseLocation(element, {
            x: downEvent.clientX,
            y: downEvent.clientY,
          }),
          calculateMouseLocation(element, {
            x: event.clientX,
            y: event.clientY,
          })
        );

        downEvent = event;
      }
    };

    const handlePointerUp = () => {
      if (downEvent) {
        onGestureEnd?.(
          calculateMouseLocation(element, {
            x: downEvent.clientX,
            y: downEvent.clientY,
          })
        );
      }
      downEvent = null;
    };

    if (element) {
      element.addEventListener("pointerdown", handlePointerDown);
      element.addEventListener("pointermove", handlePointerMove);
      element.addEventListener("pointerup", handlePointerUp);
    }

    return () => {
      if (element) {
        element.removeEventListener("pointerdown", handlePointerDown);
        element.removeEventListener("pointermove", handlePointerMove);
        element.removeEventListener("pointerup", handlePointerUp);
      }
    };
  }, [element, onGesture, onGestureEnd, onGestureCancel]);
};
export default useOneFinger;

// https://pqina.nl/blog/total-canvas-memory-use-exceeds-the-maximum-limit/

const releaseCanvas = (canvas: HTMLCanvasElement) => {
  setTimeout(() => {
    canvas.width = 1;
    canvas.height = 1;
    const ctx = canvas.getContext("2d");
    ctx && ctx.clearRect(0, 0, 1, 1);
  }, 2000);
};

export default releaseCanvas;

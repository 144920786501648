import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { ReactNode } from "react";
import { AnimatePresence, motion } from 'framer-motion'

const ModalButtonArea = ({ children }: { children: ReactNode }) => {
    return (
        <div className="flex gap-3 items-center p-4 border-t">
            {children}
        </div>
    )
}

const ModalDescription = ({ children }: { children: ReactNode }) => {
    return <div className='mb-3 p-4 text-gray-700 text-lg'>{children}</div>
}

const Modal = ({ isOpen, onClose, children }: { isOpen: boolean, onClose: () => void, children: ReactNode }) => {
    return (
        <>
            {isOpen && (<div className="fixed inset-0 bg-gray-200/60" />)}
            <AnimatePresence>
                {isOpen && (
                    <Dialog static open={isOpen} onClose={onClose} className="relative z-50">
                        <DialogPanel className="max-w-lg space-y-4 border bg-white rounded-xl ">
                            <DialogTitle className="font-bold text-xl text-gray-700 border-b p-4">
                            </DialogTitle>
                        </DialogPanel>

                        <motion.div
                            initial={{ y: "-100%" }}
                            animate={{ y: 0 }}
                            exit={{ y: "-100%" }}
                            className="fixed inset-0 flex w-screen items-center justify-center p-4">
                            <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                                <DialogPanel className="max-w-lg space-y-4 border bg-white rounded-xl ">
                                    {children}
                                </DialogPanel>
                            </div>
                        </motion.div>
                    </Dialog>
                )}
            </AnimatePresence>
        </>
    )
}

export { Modal, ModalDescription, ModalButtonArea }
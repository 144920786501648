class UndoManager<Type> {
  private history: Type[];
  private index: number;

  constructor(initialState: Type) {
    this.history = [initialState];
    this.index = 0;
  }

  public addState(state: Type): void {
    this.history = this.history.slice(0, this.index + 1).concat([state]);
    this.index = this.history.length - 1;
  }

  public canUndo(): boolean {
    return this.index > 0;
  }

  public undo(): Type {
    if (this.canUndo()) {
      this.index -= 1;
      return this.history[this.index];
    }
    throw new Error("Cannot undo");
  }

  public canRedo(): boolean {
    return this.index < this.history.length - 1;
  }

  public redo(): Type {
    if (this.canRedo()) {
      const item = this.history[this.index + 1];
      this.index += 1;
      return item;
    }
    throw new Error("Cannot redo");
  }

  public currentUndo(): Type {
    return this.history[this.index];
  }
}

export default UndoManager;

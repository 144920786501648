import UndoManager from "./UndoManager";

const storeUndo = ({
  canvas,
  undoManager,
}: {
  canvas: HTMLCanvasElement;
  undoManager: UndoManager<ImageData>;
}) => {
  const context = canvas.getContext("2d", {
    willReadFrequently: true,
  })!;

  undoManager.addState(
    context.getImageData(0, 0, canvas!.width, canvas!.height)
  );
};
export default storeUndo;

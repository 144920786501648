import { sortBy } from "lodash-es";
import { useEffect, useRef } from "react";
import {
  compose,
  identity,
  scale,
  toCSS,
  applyToPoint,
  fromOneMovingPoint,
  decomposeTSR,
  translate,
} from "transformation-matrix";

type TouchCoordinates = { id: number; x: number; y: number };

const useManipulate = ({
  element,
}: {
  element: HTMLElement | null;
}): {
  resetManipulation: () => void;
} => {
  const matrix = useRef(identity());
  const touchStartCoordinates = useRef<TouchCoordinates[]>([]);

  const resetManipulation = () => {
    matrix.current = identity();
    element!.style.transform = "";
  };

  useEffect(() => {
    if (!element) {
      return;
    }

    const applyTransform = () => {
      element.style.transformOrigin = "0 0";
      element.style.transform = toCSS(matrix.current);
    };

    // https://github.com/chrvadala/transformation-matrix/blob/main/src/fromMovingPoints.js
    const fromTwoMovingPoints = (
      startingPoint1: TouchCoordinates,
      startingPoint2: TouchCoordinates,
      endingPoint1: TouchCoordinates,
      endingPoint2: TouchCoordinates
    ) => {
      const translationMatrix = fromOneMovingPoint(
        startingPoint1,
        endingPoint1
      );

      const pointA = applyToPoint(translationMatrix, startingPoint2);
      const center = endingPoint1;
      const pointB = endingPoint2;

      // finds scale matrix
      const d1 = Math.sqrt(
        Math.pow(pointA.x - center.x, 2) + Math.pow(pointA.y - center.y, 2)
      );
      const d2 = Math.sqrt(
        Math.pow(pointB.x - center.x, 2) + Math.pow(pointB.y - center.y, 2)
      );
      const scalingLevel = d2 / d1;
      const scalingMatrix = scale(
        scalingLevel,
        scalingLevel,
        center.x,
        center.y
      );

      return compose([translationMatrix, scalingMatrix]);
    };

    const getOrderedTouchCoordinates = (event: TouchEvent) => {
      const coordinates = [];
      for (let i = 0; i < event.touches.length; i++) {
        const touch = event.touches[i];
        coordinates.push({
          id: touch.identifier,
          x: touch.clientX,
          y: touch.clientY,
        });
      }
      return sortBy(coordinates, (c) => c.id);
    };

    const handleTouchStart = (event: TouchEvent) => {
      touchStartCoordinates.current = getOrderedTouchCoordinates(event);
    };

    const handleTouchMove = (event: TouchEvent) => {
      const coordinates = getOrderedTouchCoordinates(event);

      if (touchStartCoordinates.current.length !== 2) return;
      if (coordinates.length !== 2) return;

      const additionalMatrix = fromTwoMovingPoints(
        touchStartCoordinates.current[0],
        touchStartCoordinates.current[1],
        coordinates[0],
        coordinates[1]
      );

      const nextMatrix = compose(additionalMatrix, matrix.current);

      matrix.current = nextMatrix;

      touchStartCoordinates.current = coordinates;

      applyTransform();
    };

    const handleTouchEnd = (event: TouchEvent) => {
      const coordinates = getOrderedTouchCoordinates(event);

      touchStartCoordinates.current = coordinates;

      // reset translation when zoom is small
      const decomposed = decomposeTSR(matrix.current);
      if (decomposed.scale.sx <= 1.1 || decomposed.scale.sy <= 1.1) {
        matrix.current = compose(translate(0, 0), scale(1, 1));
      }

      applyTransform();
    };

    const handleTouchCancel = () => {
      touchStartCoordinates.current = [];
      matrix.current = identity();
      applyTransform();
    };

    element.addEventListener("touchstart", handleTouchStart);
    element.addEventListener("touchmove", handleTouchMove);
    element.addEventListener("touchend", handleTouchEnd);
    element.addEventListener("touchcancel", handleTouchCancel);

    return () => {
      element.removeEventListener("touchstart", handleTouchStart);
      element.removeEventListener("touchmove", handleTouchMove);
      element.removeEventListener("touchend", handleTouchEnd);
      element.removeEventListener("touchcancel", handleTouchCancel);
    };
  }, [element]);

  return { resetManipulation };
};

export default useManipulate;

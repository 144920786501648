import { useState } from "react";
import retrieveStoredDrawing from "../utils/retrieveStoredDrawing";
import Drawing from "../types/Drawing";

const StoredImage = ({ drawing }: { drawing: Drawing; }) => {
    const [src, setSrc] = useState<string>(drawing.path);
    retrieveStoredDrawing(drawing).then((storedDrawing) => storedDrawing && setSrc(storedDrawing));

    return <img src={src} alt={`Coloring image ${drawing.number}`} />;
};
export default StoredImage

import ImpactButton from "../ImpactButton"
import ColourPie from "../ColourPie";
import { useAtom, useAtomValue } from "jotai";
import { colourChoiceAtom, palettesOpenAtom } from "./editorAtoms";

const PalettePickerButton = () => {
    const colourChoice = useAtomValue(colourChoiceAtom)
    const [palettesOpen, setPalettesOpen] = useAtom(palettesOpenAtom)

    const handleClick = () => {
        setPalettesOpen(!palettesOpen)
    }

    return (
        <ImpactButton onClick={handleClick} className="pointer-events-auto rounded-full border-2 shadow p-3 bg-gray-50 dark:bg-gray-600 dark:border-gray-700">
            <ColourPie size={30} colours={colourChoice.palette.colours} />
        </ImpactButton>
    )
}
export default PalettePickerButton
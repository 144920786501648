import { Point } from "../../types/Point";
import calculateRay from "../calculateRay";

import zigTexture from "../../../brushes/zig.png";
import Texture from "../../types/Texture";
import Drawer from "../../types/Drawer";
import { memoize } from "lodash-es";
import loadImage from "../loadImage";
import recolourTexture from "../recolourTexture";
import drawTexture from "../drawTexture";

let texture: Texture | null = null;

const loadTexture = async () => {
  if (texture) {
    return;
  }
  texture = await loadImage(zigTexture);
};

const drawWithZig: Drawer = ({
  context,
  brushSize,
  colour,
  start,
  end,
}: {
  context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D;
  brushSize: number;
  colour: string;
  start: Point;
  end: Point;
}) => {
  const calculateTexturePosition = (point: Point) => {
    const x = point.x - brushSize / 2;
    const y = point.y - brushSize / 2;
    return { x, y };
  };

  const memoizedRecolourTexture = memoize(recolourTexture, () => colour);
  const randomRotation = () => Math.round(Math.random() * 180);

  context.save();

  const positions =
    start.x === end.x && start.y === end.y
      ? calculateRay(start, start)
      : calculateRay(start, end);

  for (const position of positions) {
    drawTexture({
      texture: memoizedRecolourTexture({ texture: texture!, colour }),
      context,
      position: calculateTexturePosition(position),
      width: brushSize,
      height: brushSize,
      angle: randomRotation(),
    });
  }

  context.restore();
};

const loadTextureAndDraw: Drawer = (params) => {
  loadTexture().then(() => {
    drawWithZig(params);
  });
};

export default loadTextureAndDraw;

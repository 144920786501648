import black from '../../assets/badges/ios/black.svg'
import { Link } from 'wouter'

const Landing = () => {
    return (
        <div>
            <div>
                <div className='h-16 flex items-center p-3 flex-wrap gap-3'>
                    <div className='flex items-center h-12 gap-3'>
                        <img src="/icon.png" alt="Color Icon" className='h-full mx-auto' />
                        {/* <div className='h-10'>
                            <LogoText />
                        </div> */}
                    </div>
                    <div className='grow'></div>
                    <Link href="/support" className='text-lg text-gray-500 dark:text-white underline'>Support</Link>
                    <Link href="/privacy" className='text-lg text-gray-500 dark:text-white underline'>Privacy</Link>
                </div>
            </div>

            <div className="container mx-auto px-3 pt-12 pb-24 dark:prose-invert prose lg:prose-xl">
                <div>
                    <p className='text-center'>
                        <strong>Color.</strong> The coloring app for iOS.
                    </p>
                </div>
                <div className='grid md:grid-cols-3 gap-3'>
                    {[
                        "/screenshots/9ec472af-85f6-46e7-88db-e48df438a609.png",
                        "/screenshots/90ecaa06-c59e-45ef-89ee-3ed12115d733.png",
                        "/screenshots/c7f62b8b-8baf-44bd-b4ed-72ca18a17dff.png",
                    ].map((screenshot) => (
                        <div key={screenshot} className='flex items-center justify-center'>
                            <div className='w-52'>
                                <div
                                    className='pt-[2px] pb-[23px] px-[11px] m-2 bg-cover overflow-hidden'
                                    style={{
                                        backgroundImage: "url(/bezels/iphone-16-pro-black-titanium-portrait.png)",
                                    }}
                                >
                                    <img src={screenshot} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex items-center justify-center'>
                    <a title="Download Color App on the Apple App Store" href="https://apps.apple.com/us/app/color-adult-coloring-book/id6529543726">
                        <img src={black} alt="Download on the App Store" />
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Landing
import { useId } from "react"
import describePieArc from "../utils/describePieArc"

const ColourPie = ({ size, colours }: { size: number, colours: string[] }) => {
    const radius = size / 2
    const cx = size / 2
    const cy = size / 2
    const maskId = useId()

    const colourStartAngle = (paletteSize: number, index: number) => {
        const arcAngle = 360 / paletteSize
        return (360 / paletteSize) * index - (arcAngle / 2)
    }

    const colourEndAngle = (paletteSize: number, index: number) => {
        const arcAngle = 360 / paletteSize
        return colourStartAngle(paletteSize, index) + arcAngle
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
            <mask id={maskId}>
                <rect x="0" y="0" width={size} height={size} fill="white" />
                <ellipse
                    cx={cx}
                    cy={cy}
                    rx={radius / 3}
                    ry={radius / 3}
                    fill="black" />
            </mask>
            {colours.map((colour, colourIndex) => (
                <path
                    key={colourIndex}
                    d={describePieArc(cx, cy, radius, colourStartAngle(colours.length, colourIndex), colourEndAngle(colours.length, colourIndex) + 1)}
                    fill={colour}
                    mask={`url(#${maskId})`}
                />
            ))}
        </svg>
    )
}
export default ColourPie
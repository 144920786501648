import { useAtomValue } from "jotai";
import Drawer from "../types/Drawer";
import { Point } from "../types/Point";
import createClippedDrawingContext from "../utils/createClippedDrawingContext";
import { ImageRegionData } from "../utils/ImageRegionData";
import Vibrator from "../utils/Vibrator";
import {
  brushSizeAtom,
  canvasAtom,
  colourChoiceAtom,
  imageAtom,
  imageRegionsAtom,
  lineLockAtom,
  undoManagerAtom,
} from "../components/Editor/editorAtoms";

const useTool = ({
  drawer,
  onCanvasChange,
}: {
  drawer: Drawer;
  onCanvasChange: () => void;
}) => {
  const image = useAtomValue(imageAtom);
  const canvas = useAtomValue(canvasAtom);
  const colourChoice = useAtomValue(colourChoiceAtom);
  const lineLock = useAtomValue(lineLockAtom);
  const brushSize = useAtomValue(brushSizeAtom);
  const imageRegions = useAtomValue(imageRegionsAtom);
  const undoManager = useAtomValue(undoManagerAtom);

  let drawingContext:
    | OffscreenCanvasRenderingContext2D
    | CanvasRenderingContext2D
    | null = null;
  let destinationContext: CanvasRenderingContext2D | null = null;
  let currentRegion: ImageRegionData | null = null;
  let drewSomething = false;

  const restoreCurrentUndo = () => {
    const context = canvas!.getContext("2d", {
      willReadFrequently: true,
    })!;

    context.putImageData(undoManager!.currentUndo(), 0, 0);
  };

  const draw = (
    start: { x: number; y: number },
    end: { x: number; y: number }
  ) => {
    if (!imageRegions) {
      return;
    }
    if (!canvas) {
      return;
    }
    if (!image) {
      return;
    }

    if (!currentRegion) {
      currentRegion = lineLock
        ? imageRegions.find(start.x, start.y)
        : imageRegions.merged();

      drawingContext = createClippedDrawingContext(
        canvas,
        image,
        currentRegion
      );
      destinationContext = canvas.getContext("2d")!;
    }

    if (currentRegion && drawingContext && destinationContext) {
      drawer({
        context: drawingContext,
        colour: colourChoice.colourModifier,
        brushSize,
        start,
        end,
      });

      destinationContext.drawImage(drawingContext.canvas, 0, 0);

      Vibrator.canvasDraw();
    }
  };

  return {
    onGesture: (start: Point, end: Point) => {
      if (!image || !canvas || !imageRegions) {
        return;
      }

      draw(start, end);
      drewSomething = true;
    },
    onGestureEnd: (end: Point) => {
      if (!drewSomething) {
        draw(end!, end!);
      }
      onCanvasChange();

      drawingContext = null;
      destinationContext = null;
      currentRegion = null;
      drewSomething = false;
    },
    onGestureCancel: () => {
      if (drewSomething) {
        restoreCurrentUndo();
      }
      drewSomething = false;
    },
  };
};

export default useTool;

import Drawing from "../../types/Drawing";
import { drawingAtom } from "../Editor/editorAtoms";
import StoredImage from "../StoredImage";
import { parse, subDays } from "date-fns";
import Editor from "../Editor";
import { useSetAtom } from "jotai";

const FeedDrawing = ({ drawing, onEditorClose }: { drawing: Drawing, onEditorClose: () => void }) => {
    const setDrawing = useSetAtom(drawingAtom);

    const handleDrawingClick = () => {
        setDrawing(drawing);
    }

    const isNew = parse(drawing.date, "yyyy-MM-dd", new Date()) > subDays(new Date(), 3);

    return (
        <div
            className="animate-in zoom-in duration-300"
        >
            <div className="relative">
                <button
                    className="flex-none w-fit rounded-xl overflow-hidden shadow"
                    onClick={handleDrawingClick}
                >
                    <StoredImage drawing={drawing} />
                </button>
                {isNew && (
                    <div className="inline absolute -top-2 -right-2 font-bold text-sm uppercase bg-blue-300 text-blue-800 px-2 rounded-lg">
                        new
                    </div>
                )}
            </div>
            <div className="">
                <span className="text-sm text-gray-400 dark:text-gray-50">
                    {drawing.category}
                </span>
            </div>
            <Editor onClose={onEditorClose} />
        </div>
    );
};

export default FeedDrawing
import chroma from "chroma-js";
import { Point } from "../../types/Point";
import { randomPointInsideCircle } from "../randomPointInsideCircle";

const drawWithSpray = ({
  context,
  colour,
  brushSize,
  end,
}: {
  context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D;
  colour: string;
  brushSize: number;
  start: Point;
  end: Point;
}) => {
  const radius = (brushSize * Math.random()) / 2;
  const alpha = Math.random() * 0.5 + 0.5;
  const randomColour = chroma(colour).alpha(alpha).hex();

  const randomPoint = randomPointInsideCircle(end.x, end.y, brushSize / 2);

  context.beginPath();
  context.arc(randomPoint.x, randomPoint.y, radius, 0, Math.PI * 2, true);
  context.fillStyle = randomColour;
  context.fill();
};

export default drawWithSpray;

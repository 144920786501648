import { Point } from "../types/Point";

const drawTexture = ({
  texture,
  context,
  position,
  width,
  height,
  angle,
}: {
  context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D;
  texture: HTMLCanvasElement | OffscreenCanvas;
  position: Point;
  width: number;
  height: number;
  angle: number;
}) => {
  context.translate(position.x + width / 2, position.y + height / 2);
  context.rotate((angle * Math.PI) / 180);
  context.drawImage(texture, -width / 2, -height / 2, width, height);
  context.rotate((-angle * Math.PI) / 180);
  context.translate(-position.x - width / 2, -position.y - height / 2);
};

export default drawTexture;

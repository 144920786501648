import { motion } from "framer-motion"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import useDisableLoupe from "../hooks/useDisableLoupe"
import chroma from "chroma-js"
import describePieArc from "../utils/describePieArc"
import { Point } from "../types/Point"
import distanceBetweenTwoPoints from "../utils/distanceBetweenPoints"

const ColourSpinny = ({ className, selectedColour, colours, onColourHover, onColourSelect }: { selectedColour: string, className?: string, colours: string[], onColourHover?: (colour: string) => void, onColourSelect?: (colour: string) => void }) => {
    const wheelRadius = 100
    const arcAngle = (360.0 / colours.length)

    const [selectedIndex, setSelectedIndex] = useState(colours.indexOf(selectedColour))
    const [hoverIndex, setHoverIndex] = useState(-1)
    const [rotation, setRotation] = useState<number>(0)
    const [duration, setDuration] = useState<number>(0)

    const ref = useRef<SVGSVGElement>(null)

    const calculateArcStart = useCallback((index: number) => {
        return (arcAngle * index)
    }, [arcAngle])

    const snapAngles = useMemo(() => {
        return colours.map((_, index) => 360 - (calculateArcStart(index) + (arcAngle / 2)))
    }, [arcAngle, calculateArcStart, colours])

    const calculateArcEnd = (index: number) => {
        return calculateArcStart(index) + arcAngle
    }

    const closestSnapAngle = () => {
        const closestSnaps = [...snapAngles].sort((a, b) => {
            return (Math.abs(rotation - a) - Math.abs(rotation - b))
        })
        const closestSnap = closestSnaps[0]
        return { index: snapAngles.indexOf(closestSnap), angle: closestSnap }
    }

    const constrainRotation = (rotation: number): number => {
        while (rotation < 0 || rotation > 360.0) {
            if (rotation < 0) {
                rotation += 360.0
            } else if (rotation > 360.0) {
                rotation -= 360.0
            }
        }
        return rotation
    }

    useEffect(() => {
        setRotation(snapAngles[selectedIndex])
    }, [snapAngles, selectedIndex, setRotation])

    useEffect(() => {
        setSelectedIndex((selectedIndex) =>
            selectedColour === colours[selectedIndex] ? selectedIndex : colours.indexOf(selectedColour)
        )
    }, [colours, selectedColour])

    useDisableLoupe(ref.current ? ref.current as SVGElement : null)


    const touchStartLocation = useRef<Point | null>(null)

    const handleTouchStart = (event: React.TouchEvent<SVGPathElement>) => {
        if (event.touches.length === 1) {
            touchStartLocation.current = { x: event.touches[0].clientX, y: event.touches[0].clientY }
        } else {
            touchStartLocation.current = null
        }
    }

    const handleTouchMove = (event: React.TouchEvent<SVGPathElement>) => {
        if (touchStartLocation.current) {
            const distance = distanceBetweenTwoPoints(touchStartLocation.current, { x: event.touches[0].clientX, y: event.touches[0].clientY })
            if (distance > 3) {
                touchStartLocation.current = null
            }
        }
    }

    const handleTouchEnd = (colourIndex: number) => {
        if (touchStartLocation.current) {
            setSelectedIndex(colourIndex)
            onColourSelect && onColourSelect(colours[colourIndex])
            touchStartLocation.current = null
        }
    }

    const handleTouchCancel = () => {
        touchStartLocation.current = null
    }

    const handleMouseClick = (colourIndex: number) => {
        setSelectedIndex(colourIndex)
        onColourSelect && onColourSelect(colours[colourIndex])
    }

    return (
        <svg className={className} ref={ref} xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 200 200"
        >
            <motion.g animate={{ rotate: rotation }} transition={{ duration: duration }}
                onPan={(_, info) => {
                    setDuration(0)
                    setRotation(constrainRotation(rotation + (info.delta.x * 0.3)))

                    const { index } = closestSnapAngle()
                    if (index !== hoverIndex) {
                        setHoverIndex(index)
                        onColourHover && onColourHover(colours[index])
                    }
                }}
                onPanEnd={() => {
                    setDuration(0.1)

                    const { index } = closestSnapAngle()
                    setSelectedIndex(index)
                    onColourSelect && onColourSelect(colours[index])
                }}
            >
                {colours.map((colour, index) => (
                    <g key={`${index}-${colour}`}>
                        <path
                            d={describePieArc(100, wheelRadius, wheelRadius - 2, calculateArcStart(index), calculateArcEnd(index) + 0.1)}
                            fill={chroma(colour).darken(0.2).hex()}
                        />
                        <path
                            d={describePieArc(100, wheelRadius, wheelRadius - 3, calculateArcStart(index), calculateArcEnd(index) + 0.1)}
                            fill={chroma(colour).darken(0.3).hex()}
                        />
                        <path
                            className="pointer-events-auto"
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={() => handleTouchEnd(index)}
                            onTouchCancel={handleTouchCancel}
                            onClick={() => handleMouseClick(index)}
                            d={describePieArc(100, wheelRadius, wheelRadius - 4, calculateArcStart(index), calculateArcEnd(index) + 0.1)}
                            fill={colour}
                        />
                    </g>
                ))}
            </motion.g>
            <g>
                <path
                    className="pointer-events-none fill-transparent stroke-gray-500 stroke-1"
                    d={describePieArc(100, wheelRadius, wheelRadius - 2, 0 - (arcAngle / 2), (arcAngle / 2))}
                />
                <path
                    className="pointer-events-none fill-transparent stroke-gray-300 stroke-1"
                    d={describePieArc(100, wheelRadius, wheelRadius - 3, 0 - (arcAngle / 2) + 0.5, (arcAngle / 2) - 0.5)}
                />
                <path
                    className="pointer-events-none fill-transparent stroke-gray-500 stroke-1"
                    d={describePieArc(100, wheelRadius, wheelRadius - 4, 0 - (arcAngle / 2) + 1, (arcAngle / 2) - 1)}
                />
            </g>
        </svg>
    )
}

export default ColourSpinny
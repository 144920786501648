import { useEffect, useRef } from "react";
import ToolType from "../../types/ToolType";
import drawWithBrush from "../../utils/tools/drawWithBrush";
import drawWithSpray from "../../utils/tools/drawWithSpray";
import drawWithSplatter from "../../utils/tools/drawWithSplatter";
import drawWithZig from "../../utils/tools/drawWithZig";
import calculateRay from "../../utils/calculateRay";
import { useAtomValue } from "jotai";
import { colourChoiceAtom } from "./editorAtoms";

const BrushSample = ({ type, sampleSize, brushSize, padding }: { type: ToolType, sampleSize: number, brushSize: number, padding: number }) => {
    const ref = useRef<HTMLCanvasElement>(null);
    const colourChoice = useAtomValue(colourChoiceAtom)

    useEffect(() => {
        if (!ref.current) {
            return
        }

        const canvas = ref.current;
        const context = canvas.getContext('2d')!;

        context.clearRect(0, 0, sampleSize, sampleSize)

        const paddingOffsetByBrushSize = padding + brushSize / 2;
        const ray = calculateRay(
            { x: padding + (brushSize / 2), y: sampleSize - paddingOffsetByBrushSize },
            { x: sampleSize - paddingOffsetByBrushSize, y: paddingOffsetByBrushSize }
        )

        if (type === ToolType.Brush) {
            for (const point of ray) {
                drawWithBrush({
                    context,
                    colour: colourChoice.colourModifier,
                    brushSize,
                    start: point,
                    end: point
                })
            }
        } else if (type === ToolType.Bucket) {
            context.fillStyle = colourChoice.colourModifier
            context.fillRect(padding, padding, sampleSize - padding * 2, sampleSize - padding * 2)
        } else if (type === ToolType.Spray) {
            for (const point of ray) {
                drawWithSpray({
                    context,
                    colour: colourChoice.colourModifier,
                    brushSize,
                    start: point,
                    end: point
                })
            }
        } else if (type === ToolType.Splatter) {
            for (const point of ray) {
                drawWithSplatter({
                    context,
                    colour: colourChoice.colourModifier,
                    brushSize,
                    start: point,
                    end: point
                })
            }
        } else if (type === ToolType.Eraser) {
            context.fillStyle = colourChoice.colourModifier
            context.fillRect(padding, padding, sampleSize - padding * 2, sampleSize - padding * 2)

            drawWithBrush({
                context,
                colour: 'white',
                brushSize,
                start: { x: sampleSize / 2, y: sampleSize / 2 },
                end: { x: sampleSize / 2, y: sampleSize / 2 }
            })
            drawWithBrush({
                context,
                colour: 'white',
                brushSize,
                start: { x: sampleSize / 2, y: sampleSize / 2 },
                end: { x: sampleSize / 2, y: sampleSize / 2 }
            })
        } else if (type === ToolType.Zig) {
            Promise.all(ray.map((point) => drawWithZig({
                context,
                brushSize,
                colour: colourChoice.colourModifier,
                start: point,
                end: point
            })))
        } else {
            throw new Error(`Unsupported tool type: ${type}`)
        }
    }, [brushSize, colourChoice, type, sampleSize, padding])

    return (<canvas ref={ref} width={sampleSize} height={sampleSize} />)
}
export default BrushSample
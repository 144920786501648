import createOffscreenCanvas from "./createOffscreenCanvas";
import { ImageRegionData } from "./ImageRegionData";

const createClippedDrawingContext = (
  originalCanvas: HTMLCanvasElement,
  image: HTMLImageElement,
  imageRegion: ImageRegionData
) => {
  const { width, height } = image;
  const canvas = createOffscreenCanvas(width, height);

  const context = canvas.getContext("2d", {
    willReadFrequently: true,
  }) as CanvasRenderingContext2D;
  if (!context) {
    throw "Could not get 2d context from canvas";
  }

  const imageData = context.getImageData(0, 0, width, height);
  const data = imageData.data;

  for (const x of imageRegion.keys()) {
    for (const y of imageRegion.get(x)!.values()) {
      const pixelIndex = x + y * width;

      data[pixelIndex * 4 + 0] = 255;
      data[pixelIndex * 4 + 1] = 255;
      data[pixelIndex * 4 + 2] = 255;
      data[pixelIndex * 4 + 3] = 255;
    }
  }
  context.putImageData(imageData, 0, 0);

  context.globalCompositeOperation = "source-atop";
  context.drawImage(originalCanvas, 0, 0);

  return context;
};

export default createClippedDrawingContext;

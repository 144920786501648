import chroma from "chroma-js";
import { Point } from "../../types/Point";

const drawWithSpray = ({
  context,
  colour,
  brushSize,
  start,
  end,
}: {
  context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D;
  colour: string;
  brushSize: number;
  start: Point;
  end: Point;
}) => {
  context.lineJoin = "round";
  context.lineCap = "round";

  context.strokeStyle = chroma(colour).alpha(0.1).hex();
  context.lineWidth = brushSize;

  if (start.x === end.x && start.y === end.y) {
    context.beginPath();
    context.moveTo(start.x, start.y);
    context.lineTo(end.x + 1, end.y + 1);
    context.stroke();
  } else {
    context.beginPath();
    context.moveTo(start.x, start.y);
    context.lineTo(end.x, end.y);
    context.stroke();
  }
};

export default drawWithSpray;

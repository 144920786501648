import { useAtomValue } from "jotai";
import createClippedDrawingContext from "../utils/createClippedDrawingContext";
import drawWithBucket from "../utils/tools/drawWithBucket";
import {
  canvasAtom,
  colourChoiceAtom,
  imageAtom,
  imageRegionsAtom,
  lineLockAtom,
} from "../components/Editor/editorAtoms";

const useBucket = ({ onCanvasChange }: { onCanvasChange: () => void }) => {
  const image = useAtomValue(imageAtom);
  const canvas = useAtomValue(canvasAtom);
  const colourChoice = useAtomValue(colourChoiceAtom);
  const lineLock = useAtomValue(lineLockAtom);
  const imageRegions = useAtomValue(imageRegionsAtom);

  return {
    onGesture: () => {},
    onGestureEnd: (end: { x: number; y: number }) => {
      if (!image || !canvas || !imageRegions) {
        return;
      }

      const region = lineLock
        ? imageRegions.find(end.x, end.y)
        : imageRegions.merged();

      if (region) {
        const drawingContext = createClippedDrawingContext(
          canvas,
          image,
          region
        );

        drawWithBucket({
          context: drawingContext,
          colour: colourChoice.colourModifier,
        });
        const destinationContext = canvas.getContext("2d")!;
        destinationContext.drawImage(drawingContext.canvas, 0, 0);

        onCanvasChange();
      }
    },
    onGestureCancel: () => {},
  };
};
export default useBucket;

const Support = () => {
    return (
        <div className="container mx-auto px-3 pt-12 pb-24 prose lg:prose-xl">
            <h1>Support for Color</h1>
            <p>
                For any support questions please contact <a href="mailto:andrew@andrewvos.com">
                    support
                </a>
            </p>
        </div>
    );
}
export default Support
import { Point } from "../types/Point";

/**
 * Calculates a ray from the start point to the end point.
 *
 * @param start - The starting point of the ray.
 * @param end - The ending point of the ray.
 * @returns An array of points representing the ray from start to end.
 */
const calculateRay = (start: Point, end: Point) => {
  const result = [{ ...start }];
  const current = { ...start };

  while (current.x !== end.x || current.y !== end.y) {
    if (end.x > current.x) {
      current.x++;
    } else if (end.x < current.x) {
      current.x--;
    }

    if (end.y > current.y) {
      current.y++;
    } else if (end.y < current.y) {
      current.y--;
    }

    result.push({ ...current });
  }
  return result;
};

export default calculateRay;

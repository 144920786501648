const send = (message: string) => {
  if (
    // @ts-expect-error: whatever
    !window.webkit ||
    // @ts-expect-error: whatever
    !window.webkit.messageHandlers ||
    // @ts-expect-error: whatever
    !window.webkit.messageHandlers[message]
  ) {
    return;
  }

  // @ts-expect-error: whatever
  window.webkit.messageHandlers[message].postMessage(message);
};

export default {
  buttonPress: () => {
    send("vibrate-impact-button-press");
  },
  canvasDraw: () => {
    send("vibrate-impact-canvas-draw");
  },
};

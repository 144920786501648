import Drawing from "../types/Drawing";

const fetchFeed = async () => {
  const response = await fetch("/feed.json");
  if (!response.ok) {
    throw new Error("Failed to fetch feed");
  }

  const feed: Drawing[] = await response.json();
  return feed;
};

export default fetchFeed;

import { useAtom, useAtomValue } from "jotai";
import ImpactButton from "../ImpactButton";
import ToolTypeIcon from "../ToolTypeIcon";
import BrushSample from "./BrushSample";
import { brushTypeAtom, toolsOpenAtom } from "./editorAtoms";

const ToolPickerButton = () => {
    const [toolsOpen, setToolsOpen] = useAtom(toolsOpenAtom)
    const brushType = useAtomValue(brushTypeAtom)

    const handleClick = () => {
        setToolsOpen(!toolsOpen)
    }

    return (
        <ImpactButton onClick={handleClick} className="pointer-events-auto relative rounded-full border-2 shadow p-3 bg-gray-50 dark:bg-gray-600 dark:border-gray-700">
            <div className="inline absolute p-1 -top-2 -left-2 bg-gray-50 text-gray-600 border-2 shadow rounded-full dark:text-gray-50 dark:bg-gray-600 dark:hover:bg-gray-600 dark:border-gray-700" >
                <ToolTypeIcon toolType={brushType} />
            </div>
            <BrushSample type={brushType} sampleSize={30} brushSize={15} padding={2} />
        </ImpactButton>
    )
}
export default ToolPickerButton;
const Privacy = () => {
    const appName = "Color: Adult Coloring Book"
    const companyName = "Andrew Vos"
    const supportEmail = "andrew@andrewvos.com"

    return (
        <div className="container mx-auto px-3 pt-12 pb-24 prose lg:prose-xl">
            <h1>Privacy Policy</h1>
            <strong>
                Last Updated: 4th August 2024
            </strong>

            <p>The following Privacy Policy applies to the {appName} app.</p>

            <h2>Introduction</h2>
            <p>
                Welcome to {appName}, an application developed by {companyName} ("we", "our", or "us").
                Your privacy is important to us, and we are committed to protecting your personal information.
                This Privacy Policy explains how we collect, use, and share your information when you use the {appName} app ("App").
            </p>

            <h2>Information We Collect</h2>

            <h3>Contest Entries</h3>
            <p>
                If you submit your drawing to our weekly contest, we collect the following information:

            </p>
            <ul>
                <li>Name</li>
                <li>Age</li>
                <li>Email</li>
                <li>Drawing</li>
            </ul>
            <p>
                If you win the contest, we will display your name, age and drawing on
                the main app feed. This will be <strong>publicly visible</strong>.
            </p>
            <p>
                The contest winner is chosen by a panel of judges.
            </p>
            <p>
                To request that your data is deleted please <a href={`mailto:${supportEmail}`}>contact support</a>.
            </p>

            <h3>Analytics and Error Management</h3>
            <p>
                <strong>Sentry:</strong> We use Sentry for error tracking and management. When an error occurs, Sentry collects information about the error and the environment in which it happened. This information helps us diagnose and fix issues.
            </p>

            <h2>How We Use Your Information</h2>
            <p>We use the information we collect to:</p>
            <ul>
                <li>Improve and optimize the App through analytics.</li>
                <li>Monitor and fix errors to ensure a smooth user experience.</li>
            </ul>

            <h2>Sharing Your Information</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share your information in the following scenarios:</p>
            <ul>
                <li>
                    <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.
                </li>
            </ul>

            <h2>Data Security</h2>
            <p>We implement a variety of security measures to maintain the safety of your personal information. Your data is transmitted over secure channels and stored on secure servers. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee its absolute security.</p>
            <h2>Your Consent</h2>
            <p>By using the {appName} app, you consent to our Privacy Policy.</p>

            <h2>Changes to Our Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.</p>
        </div>
    )
}
export default Privacy
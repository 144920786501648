import classNames from "classnames";
import BottomModal from "./BottomModal";
import ColourPie from "../ColourPie";
import ImpactButton from "../ImpactButton";
import palettes from "../../palettes";
import { useAtom } from "jotai";
import { colourChoiceAtom, palettesOpenAtom } from "./editorAtoms";

const PalettePicker = () => {
    const [palettesOpen, setPalettesOpen] = useAtom(palettesOpenAtom)
    const [colourChoice, setColourChoice] = useAtom(colourChoiceAtom)

    const handlePaletteClick = (palette: { name: string, colours: string[] }) => {
        setColourChoice({
            colour: palette.colours[0],
            colourModifier: palette.colours[0],
            palette
        })
        setPalettesOpen(false)
    }

    const handleClose = () => {
        setPalettesOpen(false)
    }

    return (
        <BottomModal name="palettes" open={palettesOpen} onClose={handleClose}>
            {palettes.map((palette, index) => (
                <ImpactButton
                    key={index}
                    onClick={() => handlePaletteClick(palette)}
                    className={classNames(
                        "rounded-lg border shadow dark:border-gray-800",
                        { "bg-gray-100 dark:bg-gray-600": palette.name === colourChoice.palette.name },
                    )}>
                    <div className="">
                        <div
                            className="flex items-center border-b dark:border-gray-800 justify-center px-2 text-sm font-medium text-gray-500 dark:text-gray-50 whitespace-nowrap text-nowrap">
                            {palette.name}
                        </div>
                        <div className="p-2">
                            <ColourPie size={100} colours={palette.colours} />
                        </div>
                    </div>
                </ImpactButton>
            ))}
        </BottomModal>
    )
}
export default PalettePicker;
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Route, Switch } from "wouter";
import './index.css'
import { Analytics } from "@vercel/analytics/react"

import App from "./components/App";

import * as Sentry from "@sentry/react";
import Support from './pages/Support';
import Privacy from './pages/Privacy';
import Landing from './pages/Landing';
Sentry.init({
  dsn: "https://425c982bfc4dd280a450c267026b93d2@o4507707470839808.ingest.de.sentry.io/4507707472740432",
  integrations: [],
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Switch>
      <Route path="/">
        <Landing />
      </Route>
      <Route path="/app">
        <App />
      </Route>
      <Route path="/support">
        <Support />
      </Route>
      <Route path="/privacy">
        <Privacy />
      </Route>
    </Switch>
    <Analytics />
  </React.StrictMode>,
)
import { atom } from "jotai";

import ToolType from "../../types/ToolType";
import UndoManager from "../../utils/UndoManager";
import ImageRegions from "../../utils/ImageRegions";
import palettes from "../../palettes";
import Drawing from "../../types/Drawing";
import ColourChoice from "../../types/ColourChoice";

const imageAtom = atom<HTMLImageElement | null>(null);
const drawingAtom = atom<Drawing | null>(null);
const canvasAtom = atom<HTMLCanvasElement | null>(null);

const toolsOpenAtom = atom<boolean>(false);
const palettesOpenAtom = atom<boolean>(false);
const lineLockAtom = atom<boolean>(true);
const brushTypeAtom = atom<ToolType>(ToolType.Brush);
const brushSizeAtom = atom<number>(60);
const colourChoiceAtom = atom<ColourChoice>({
  colour: palettes[0].colours[0],
  colourModifier: palettes[0].colours[0],
  palette: palettes[0],
});
const recentColourChoicesAtom = atom<ColourChoice[]>([]);
const undoManagerAtom = atom<UndoManager<ImageData> | null>(null);
const imageRegionsAtom = atom<ImageRegions | null>(null);
const isSubmitToContestModalOpenAtom = atom<boolean>(false);
const scrollStateAtom = atom<{ [key: string]: number }>({});
export {
  imageAtom,
  drawingAtom,
  canvasAtom,
  toolsOpenAtom,
  palettesOpenAtom,
  lineLockAtom,
  brushTypeAtom,
  brushSizeAtom,
  colourChoiceAtom,
  recentColourChoicesAtom,
  undoManagerAtom,
  imageRegionsAtom,
  isSubmitToContestModalOpenAtom,
  scrollStateAtom,
};

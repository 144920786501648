import { Provider, useAtom } from "jotai"
import FeedDrawing from "./FeedDrawing"
import { useCallback, useEffect } from "react"
import { feedAtom } from "./feedAtoms"
import fetchFeed from "../../utils/fetchFeed"
import * as Sentry from '@sentry/browser';

const Feed = () => {
    const [feed, setFeed] = useAtom(feedAtom)

    const updateFeed = useCallback(async () => {
        const feed = await fetchFeed()
        setFeed(feed)
    }, [setFeed])

    useEffect(() => {
        updateFeed().catch(e => Sentry.captureException(e))
    }, [updateFeed])

    if (!feed) {
        return
    }

    return (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 touch-pan-y">
            {feed.map((drawing) => (
                <Provider key={drawing.id}>
                    <FeedDrawing drawing={drawing} onEditorClose={updateFeed} />
                </Provider>
            ))}
        </div>
    )
}
export default Feed
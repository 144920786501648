import { Eraser, Paintbrush, PaintBucket, Shapes, SprayCan } from "lucide-react"
import ToolType from "../types/ToolType"

const ToolTypeIcon = ({ toolType }: { toolType: ToolType }) => {
    const icons: Record<ToolType, JSX.Element> = {
        [ToolType.Brush]: <Paintbrush size={15} />,
        [ToolType.Eraser]: <Eraser size={15} />,
        [ToolType.Bucket]: <PaintBucket size={15} />,
        [ToolType.Spray]: <SprayCan size={15} />,
        [ToolType.Splatter]: <SprayCan size={15} />,
        [ToolType.Zig]: <Shapes size={15} />
    }
    return icons[toolType]
}

export default ToolTypeIcon;
import { getKeyValueStore } from "simple-kvs";
import Drawing from "../types/Drawing";

const saveDrawing = async ({
  canvas,
  drawing,
}: {
  canvas: HTMLCanvasElement;
  drawing: Drawing;
}) => {
  const preferences = await getKeyValueStore("Preferences");
  const dataUrl = canvas!.toDataURL();
  await preferences.set(`drawing:${drawing!.id}`, dataUrl);
};

export default saveDrawing;

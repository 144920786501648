import { useState } from "react";
import ContestContainer from "../ContestContainer"
import ImpactButton from "../ImpactButton"
import { Modal } from "../Modal"
import { LoaderCircle } from "lucide-react";
import * as Sentry from '@sentry/browser';
import { useAtomValue } from "jotai";
import { canvasAtom } from "./editorAtoms";

const ContestModal = ({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) => {
    const canvas = useAtomValue(canvasAtom)
    const [name, setName] = useState("");
    const [age, setAge] = useState(0);
    const [email, setEmail] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }

    const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAge(parseInt(event.target.value));
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault();

        setSubmitting(true);

        const base64Drawing = canvas!.toDataURL("image/jpeg").split(';base64,')[1];

        const showError = () => {
            alert("There was an error submitting your entry. Please try again later")
        }

        let response = null

        try {
            response = await fetch(import.meta.env.VITE_CONTEST_API_URL, {
                method: "POST",
                body: JSON.stringify({
                    name,
                    age,
                    email,
                    drawing: base64Drawing
                })
            });
        } catch (exception) {
            Sentry.captureException(exception)
        }

        setSubmitting(false);

        if (response && response.ok) {
            setSubmitted(true)
        } else {
            showError()
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ContestContainer>
                {submitted && (
                    <>
                        <div className="mb-4 uppercase text-5xl font-bold three-dee-shadow border-white text-pink-300 shadow-blue-400">
                            Submitted!
                        </div>
                        <div className="text-base text-pink-400 text-justify mb-4">
                            Thanks for entering! We'll be in touch if you win.
                        </div>
                        <ImpactButton onClick={onClose} className="flex items-center space-x-2 bg-pink-300 rounded-lg p-3 border-pink-400 border-2 text-pink-600 font-bold disabled:bg-gray-200 disabled:text-gray-700 disabled:border-gray-500">
                            <span>Close</span>
                        </ImpactButton>
                    </>
                )}
                {submitted || (
                    <>
                        <div className="mb-4 uppercase text-5xl font-bold three-dee-shadow border-white text-pink-300 shadow-blue-400">
                            Enter now!
                        </div>
                        <div className="text-base text-pink-400 text-justify mb-4">
                            If you win, your drawing will be featured for a week!
                            We will use your data as specified in our <span className="underline font-bold">Privacy Policy</span>.
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-3 mb-6">
                                <label className="block text-base text-pink-500">
                                    <div className="mb-1 font-bold">Name*</div>
                                    <input type="text" required autoComplete="given-name" value={name} onChange={handleNameChange} className="w-full text-lg rounded-lg p-2 shadow" />
                                </label>
                                <label className="block text-base text-pink-500">
                                    <div className="mb-1 font-bold">Age</div>
                                    <input type="number" autoComplete="age" value={age} onChange={handleAgeChange} className="w-full text-lg rounded-lg p-2 shadow" />
                                </label>
                                <label className="block text-base text-pink-500">
                                    <div className="mb-1 font-bold">Email*</div>
                                    <input type="email" required autoComplete="email" value={email} onChange={handleEmailChange} className="w-full text-lg rounded-lg p-2 shadow" />
                                </label>
                            </div>

                            <ImpactButton type="submit" disabled={submitting} className="flex items-center space-x-2 bg-pink-300 rounded-lg p-3 border-pink-400 border-2 text-pink-600 font-bold disabled:bg-gray-200 disabled:text-gray-700 disabled:border-gray-500">
                                {submitting && (
                                    <LoaderCircle size="1em" className="animate animate-spin" />
                                )}
                                <span>Submit</span>
                            </ImpactButton>
                        </form>
                    </>
                )}
            </ContestContainer>
        </Modal >
    )
}

export default ContestModal
import { getKeyValueStore } from "simple-kvs";
import Drawing from "../types/Drawing";

const retrieveStoredDrawing = async (
  drawing: Drawing
): Promise<string | null> => {
  const preferences = await getKeyValueStore("Preferences");

  return (await preferences.get<string>(`drawing:${drawing.id}`)) || null;
};
export default retrieveStoredDrawing;

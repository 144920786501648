import { useMemo, useRef } from "react";

import useManipulate from "../../hooks/useManipulate";
import useTools from "../../hooks/useTools";
import chroma from "chroma-js";
import useDisableLoupe from "../../hooks/useDisableLoupe";
import { AnimatePresence, motion } from "framer-motion";
import Canvas from "../Canvas";
import ColourSpinny from "../ColourSpinny";
import Vibrator from "../../utils/Vibrator";
import LeftButtons from "./LeftButtons";
import RightButtons from "./RightButtons";
import ToolPickerButton from "./ToolPickerButton";
import PalettePickerButton from "./PalettePickerButton";
import ToolPicker from "./ToolPicker";
import PalettePicker from "./PalettePicker";
import ContestModal from "./ContestModal";
import { useAtom, useAtomValue } from "jotai";
import { canvasAtom, colourChoiceAtom, drawingAtom, isSubmitToContestModalOpenAtom, palettesOpenAtom, recentColourChoicesAtom, undoManagerAtom } from "./editorAtoms";
import saveDrawing from "../../utils/saveDrawing";
import storeUndo from "../../utils/storeUndo";
import ColourChoice from "../../types/ColourChoice";
import { getKeyValueStore } from "simple-kvs";

const Editor = ({ onClose }: { onClose: () => void }) => {
    const drawing = useAtomValue(drawingAtom)
    const canvas = useAtomValue(canvasAtom)
    const [colourChoice, setColourChoice] = useAtom(colourChoiceAtom)
    const palettesOpen = useAtomValue(palettesOpenAtom)
    const [isSubmitToContestModalOpen, setIsSubmitToContestModalOpen] = useAtom(isSubmitToContestModalOpenAtom)
    const undoManager = useAtomValue(undoManagerAtom)
    const [recentColourChoices, setRecentColourChoices] = useAtom(
        recentColourChoicesAtom
    );

    const canvasContainerRef = useRef<HTMLDivElement>(null)
    useManipulate({
        element: canvasContainerRef.current,
    })

    useDisableLoupe(canvas)

    const storeRecentColour = () => {
        const newColours = recentColourChoices.filter((cc: ColourChoice) => {
            return (
                cc.colour !== colourChoice.colour ||
                cc.colourModifier !== colourChoice.colourModifier ||
                cc.palette.name !== colourChoice.palette.name
            );
        });
        newColours.unshift({ ...colourChoice });

        getKeyValueStore("Preferences").then((store) => {
            store.set(`recentColourChoices:${drawing!.id}`, newColours);
        });

        setRecentColourChoices(newColours);
    }

    const handleCanvasChange = () => {
        if (!drawing || !canvas || !undoManager) return

        storeUndo({ canvas, undoManager })
        storeRecentColour()
        saveDrawing({ canvas, drawing })
    }

    const modifiers = useMemo(() => {
        const amount = 0.1
        const count = 10

        const result = [colourChoice.colour]

        for (let i = 1; i < count; i++) {
            result.unshift(chroma(result[0]).darken(amount * i).hex())
        }
        for (let i = 3; i > 0; i--) {
            result.push(chroma(result[result.length - 1]).brighten(amount * i).hex())
        }

        const otherResult = [...result]
        otherResult.reverse()
        otherResult.shift()
        otherResult.pop()
        return [...result, ...otherResult]
    }, [colourChoice.colour])

    const paletteColours = useMemo(() => {
        const result = colourChoice.palette.colours
        const otherResult = [...result]
        return [...result, ...otherResult]
    }, [colourChoice])

    const handleFocusedColourChange = (colour: string) => {
        setColourChoice({
            ...colourChoice,
            colour: colour,
            colourModifier: colour
        })
        Vibrator.buttonPress()
    }

    const handleFocusedColourModifierChange = (colour: string) => {
        setColourChoice({
            ...colourChoice,
            colourModifier: colour
        })
        Vibrator.buttonPress()
    }

    const handleColourHover = () => {
        Vibrator.buttonPress()
    }

    useTools({
        onCanvasChange: handleCanvasChange
    })

    return (
        <AnimatePresence>
            {drawing && (
                <>
                    <motion.div
                        key="modal"
                        initial={{ y: "100%" }}
                        animate={{ y: 0 }}
                        exit={{ y: "100%" }}
                        transition={{ duration: 0.3 }}
                        className="fixed inset-0 z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                        <div className="fixed inset-0 bg-gray-50 dark:bg-gray-500" aria-hidden="true"></div>

                        <div
                            className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <div className="h-full touch-none fixed top-0 left-0 right-0 bottom-0">
                                    <div className="h-full">
                                        <div className="flex flex-col h-full">
                                            <div className="flex justify-center items-center flex-grow overflow-hidden pl-14 pb-[11rem] pt-2 pr-14">
                                                <div ref={canvasContainerRef} className="w-full h-full flex justify-center items-center">
                                                    <Canvas />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="pointer-events-none flex flex-col fixed top-0 bottom-44 left-0 p-2 pt-6 gap-2">
                                            <LeftButtons onCloseClick={onClose} />
                                        </div>

                                        <div className="pointer-events-none flex flex-col items-center fixed top-0 bottom-44 right-0 p-2 pt-6 gap-2">
                                            <RightButtons />
                                        </div>

                                        <div className="pointer-events-none fixed bottom-0 left-0 right-0 pt-4">
                                            <div className="absolute top-0 left-0 right-0 flex px-2">
                                                <ToolPickerButton />

                                                <div className="grow">
                                                </div>

                                                <PalettePickerButton />
                                            </div>
                                            {palettesOpen || (
                                                <div className="mx-auto bg-transparent max-w-96">
                                                    <div className="overflow-visible h-16">
                                                        <div className="animate-in zoom-in duration-300">
                                                            <ColourSpinny selectedColour={colourChoice.colourModifier} colours={modifiers} onColourHover={handleColourHover} onColourSelect={handleFocusedColourModifierChange} />
                                                        </div>
                                                    </div>
                                                    <div className="overflow-visible h-24">
                                                        <div className="animate-in zoom-in duration-300">
                                                            <ColourSpinny selectedColour={colourChoice.colour} colours={paletteColours} onColourHover={handleColourHover} onColourSelect={handleFocusedColourChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <ToolPicker />
                                        <PalettePicker />

                                    </div >
                                </div>
                            </div>
                        </div>
                    </motion.div>
                    <ContestModal isOpen={isSubmitToContestModalOpen} onClose={() => setIsSubmitToContestModalOpen(false)} />
                </>
            )}
        </AnimatePresence >
    );
}

export default Editor
/**
 * Creates an offscreen canvas with the specified width and height.
 * If the browser supports OffscreenCanvas, it will create and return an OffscreenCanvas object.
 * Otherwise, it will create and return a regular HTMLCanvasElement.
 *
 * It's only really old versions of iOS Safari that don't support OffscreenCanvas,
 * but I'm seeing errors pop up in production so... here we are.
 * @see https://caniuse.com/mdn-api_offscreencanvas
 * @param width - The width of the canvas.
 * @param height - The height of the canvas.
 * @returns The created canvas object.
 */
const createOffscreenCanvas = (
  width: number,
  height: number
): OffscreenCanvas | HTMLCanvasElement => {
  if (typeof OffscreenCanvas !== "undefined") {
    return new OffscreenCanvas(width, height);
  } else {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }
};
export default createOffscreenCanvas;

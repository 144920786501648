import classNames from "classnames"
import ImpactButton from "../ImpactButton"

const SideButton = ({ style = null, focused, onClick, children }: { style?: "contest" | null, focused?: boolean, onClick: () => void, children: React.ReactNode }) => {
    const normalStyle = [
        "text-gray-700 dark:text-gray-50",
        {
            "bg-gray-50 dark:bg-gray-600": !focused,
            "bg-gray-200 dark:bg-gray-500": focused
        }
    ]
    const contestStyle = [
        "text-pink-700 dark:text-pink-50",
        {
            "bg-pink-50 dark:bg-pink-600": !focused,
            "bg-pink-200 dark:bg-pink-500": focused
        }
    ]

    return (
        <ImpactButton
            className={
                classNames(
                    "flex items-center justify-center p-3 ",
                    style === null && normalStyle,
                    style === "contest" && contestStyle
                )
            }
            onClick={onClick}>
            <div className="flex items-center justify-center w-4 h-4">
                {children}
            </div>
        </ImpactButton>
    )
}

export default SideButton
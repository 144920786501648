import { Circle, Lock, LockOpen, Redo, Shuffle, Undo, X } from "lucide-react"
import SideButton from "./SideButton"
import palettes from "../../palettes"
import chroma from "chroma-js"
import { shuffle } from "lodash-es"
import ImageRegions from "../../utils/ImageRegions"
import { useAtom, useAtomValue } from "jotai"
import { brushSizeAtom, canvasAtom, drawingAtom, imageAtom, lineLockAtom, undoManagerAtom } from "./editorAtoms"
import releaseCanvas from "../../utils/releaseCanvas"
import saveDrawing from "../../utils/saveDrawing"
import storeUndo from "../../utils/storeUndo"

const LeftButtons = ({ onCloseClick }: { onCloseClick: () => void }) => {
    const image = useAtomValue(imageAtom)
    const canvas = useAtomValue(canvasAtom)
    const [drawing, setDrawing] = useAtom(drawingAtom)
    const undoManager = useAtomValue(undoManagerAtom)
    const [lineLock, setLineLock] = useAtom(lineLockAtom)
    const [brushSize, setBrushSize] = useAtom(brushSizeAtom)


    const undo = () => {
        if (!undoManager || !canvas || !drawing) return;

        if (undoManager.canUndo()) {
            const context = canvas.getContext("2d");
            if (!context) throw new Error("Canvas context not found");
            context.putImageData(undoManager.undo(), 0, 0);

            saveDrawing({ canvas, drawing });
        }
    }

    const redo = () => {
        if (!undoManager || !canvas || !drawing) return;

        if (undoManager.canRedo()) {
            const context = canvas.getContext("2d");
            if (!context) throw new Error("Canvas context not found");
            context.putImageData(undoManager.redo(), 0, 0);

            saveDrawing({ canvas, drawing });
        }
    }

    const handleCloseClick = () => {
        if (canvas) {
            releaseCanvas(canvas);
        }
        setDrawing(null)

        onCloseClick()
    }

    const handleShuffleClick = () => {
        if (!canvas) return
        if (!image) return

        let colours: string[] = []
        let colourIndex = 0

        palettes.forEach(palette => {
            palette.colours.forEach(colour => {
                colours.push(chroma(colour).brighten().brighten().hex())
                colours.push(chroma(colour).brighten().hex())
                colours.push(colour)
                colours.push(chroma(colour).darken().hex())
                colours.push(chroma(colour).darken().darken().hex())
            })
        })
        colours = shuffle(colours)

        const regions = new ImageRegions(image).findAll()
        const context = canvas.getContext("2d", { willReadFrequently: true });
        if (!context) {
            throw "Could not get 2d context from canvas";
        }
        context.drawImage(image, 0, 0);

        const imageData = context.getImageData(0, 0, image.width, image.height);
        const data = imageData.data;

        const drawRegions = () => {
            regions.forEach((region) => {
                const colour = chroma(colours[colourIndex]).rgba()

                for (const x of region.keys()) {
                    for (const y of region.get(x)!.values()) {
                        const dataPixelIndex = (x + y * image.width) * 4;
                        data[dataPixelIndex + 0] = colour[0];
                        data[dataPixelIndex + 1] = colour[1];
                        data[dataPixelIndex + 2] = colour[2];
                        data[dataPixelIndex + 3] = 255;
                    }
                }

                colourIndex++
                if (colourIndex === colours.length - 1) {
                    colourIndex = 0
                }
            })
            context.putImageData(imageData, 0, 0);

            storeUndo({ canvas, undoManager: undoManager! })
            saveDrawing({ canvas, drawing: drawing! })
        }
        drawRegions()
    }

    return (
        <>
            <div className="pointer-events-auto w-10 rounded-full shadow overflow-hidden">
                <SideButton onClick={handleCloseClick}>
                    <X size="1em" />
                </SideButton>
            </div>

            <div className="flex flex-col justify-center grow gap-2">
                <div className="pointer-events-auto w-10 rounded-full shadow overflow-hidden">
                    <SideButton onClick={() => undo()}>
                        <Undo size="1em" />
                    </SideButton>
                    <SideButton onClick={() => redo()}>
                        <Redo size="1em" />
                    </SideButton>
                </div>
                <div className="pointer-events-auto w-10 rounded-full shadow overflow-hidden">
                    <SideButton onClick={handleShuffleClick}>
                        <Shuffle size="1em" />
                    </SideButton>
                </div>
                <div className="pointer-events-auto w-10 rounded-full shadow overflow-hidden">
                    <SideButton onClick={() => setLineLock(!lineLock)}>
                        {lineLock ? <Lock size="1em" /> : <LockOpen size="1em" />}
                    </SideButton>
                </div>
                <div className="pointer-events-auto w-10 rounded-full shadow overflow-hidden hidden">
                    <SideButton onClick={() => setBrushSize(10)} focused={brushSize === 10}>
                        <Circle size="0.4em" className="fill-gray-600" />
                    </SideButton>
                    <SideButton onClick={() => setBrushSize(30)} focused={brushSize === 30}>
                        <Circle size="0.6em" className="fill-gray-600" />
                    </SideButton>
                    <SideButton onClick={() => setBrushSize(60)} focused={brushSize === 60}>
                        <Circle size="1em" className="fill-gray-600" />
                    </SideButton>
                </div>
            </div>
        </>
    )
}
export default LeftButtons
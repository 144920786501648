import classNames from "classnames";
import ToolType from "../../types/ToolType";
import ImpactButton from "../ImpactButton";
import BottomModal from "./BottomModal"
import ToolTypeIcon from "../ToolTypeIcon";
import BrushSample from "./BrushSample";
import { useAtom } from "jotai";
import { brushTypeAtom, toolsOpenAtom } from "./editorAtoms";

const ToolPicker = () => {
    const [toolsOpen, setToolsOpen] = useAtom(toolsOpenAtom)
    const [brushType, setBrushType] = useAtom(brushTypeAtom)

    const handleToolClick = (tool: ToolType) => {
        setBrushType(tool)
        setToolsOpen(false)
    }

    const handleClose = () => {
        setToolsOpen(false)
    }

    return (
        <BottomModal name="tools" open={toolsOpen} onClose={handleClose}>
            {[ToolType.Brush, ToolType.Eraser, ToolType.Bucket, ToolType.Spray, ToolType.Splatter, ToolType.Zig].map((tool) => (
                <ImpactButton
                    key={tool}
                    onClick={() => handleToolClick(tool)}
                    className={classNames(
                        "relative rounded-lg border shadow dark:border-gray-800",
                        { "bg-gray-100 dark:bg-gray-600": tool === brushType },
                    )}>
                    <div className={classNames(
                        "inline absolute p-1 -top-2 -left-2 text-gray-600 border shadow rounded-full dark:text-gray-50 dark:border-gray-800",
                        {
                            "bg-gray-100 dark:bg-gray-600": tool === brushType,
                            "bg-gray-50 dark:bg-gray-700": tool !== brushType,
                        },

                    )} >
                        <ToolTypeIcon toolType={tool} />
                    </div>
                    <div
                        className="flex items-center border-b dark:border-gray-800 justify-center px-2 text-sm font-medium text-gray-500 dark:text-gray-50 whitespace-nowrap text-nowrap"
                    >
                        {tool}
                    </div>
                    <BrushSample type={tool} sampleSize={100} brushSize={20} padding={10} />
                </ImpactButton>
            ))}
        </BottomModal>

    )

}
export default ToolPicker
function* spiralIterator(x: number, y: number, maxDistance: number) {
  const initial = { x, y };

  // right 1
  // up 1
  // left 2
  // down 2
  // right 3
  // up 3
  // left 4
  // down 4
  let current = { x: initial.x, y: initial.y };
  yield current;

  let distance = 1;
  while (distance < maxDistance) {
    // move right
    for (let i = 0; i < distance; i++) {
      current = {
        x: current.x + 1,
        y: current.y,
      };
      yield current;
    }

    // move up
    for (let i = 0; i < distance; i++) {
      current = {
        x: current.x,
        y: current.y - 1,
      };
      yield current;
    }

    distance++;

    // move left
    for (let i = 0; i < distance; i++) {
      current = {
        x: current.x - 1,
        y: current.y,
      };
      yield current;
    }

    // move down
    for (let i = 0; i < distance; i++) {
      current = {
        x: current.x,
        y: current.y + 1,
      };
      yield current;
    }

    distance++;
  }
}

export default spiralIterator;

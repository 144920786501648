import { useCallback, useEffect, useRef } from "react";
import { canvasAtom, drawingAtom, imageAtom, imageRegionsAtom, recentColourChoicesAtom, undoManagerAtom } from "./Editor/editorAtoms";
import { useAtomValue, useSetAtom } from "jotai";
import ImageRegions from "../utils/ImageRegions";
import UndoManager from "../utils/UndoManager";
import { getKeyValueStore } from "simple-kvs";
import ColourChoice from "../types/ColourChoice";
import retrieveStoredDrawing from "../utils/retrieveStoredDrawing";
import * as Sentry from '@sentry/browser';

const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            resolve(img);
        };
        img.onerror = (e) => {
            reject(e);
        };
    });
};

const Canvas = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const drawing = useAtomValue(drawingAtom);
    const setImage = useSetAtom(imageAtom);
    const setCanvas = useSetAtom(canvasAtom);
    const setUndoManager = useSetAtom(undoManagerAtom);
    const setRecentColourChoices = useSetAtom(recentColourChoicesAtom);
    const setImageRegions = useSetAtom(imageRegionsAtom);

    const initializeCanvas = useCallback(async () => {
        if (!drawing) {
            return
        }

        const canvas = canvasRef.current!;
        const image = await loadImage(drawing.path);
        const storedDrawing = await retrieveStoredDrawing(drawing);
        const storedImage = storedDrawing ? await loadImage(storedDrawing) : null;

        const context = canvas.getContext("2d", { willReadFrequently: true })!;

        canvas.width = image.width;
        canvas.height = image.height;

        if (storedImage) {
            context.drawImage(storedImage, 0, 0);
        } else if (image) {
            context.drawImage(image, 0, 0);
        }

        const store = await getKeyValueStore("Preferences");
        const recentColourChoices = await store.get<ColourChoice[]>(
            `recentColourChoices:${drawing.id}`
        );

        setImage(image);
        setCanvas(canvas);
        setUndoManager(
            new UndoManager<ImageData>(
                context.getImageData(0, 0, canvas.width, canvas.height)
            )
        );
        setRecentColourChoices(recentColourChoices || []);
        setImageRegions(new ImageRegions(image));
    }, [drawing, setCanvas, setImage, setUndoManager, setRecentColourChoices, setImageRegions]);

    useEffect(() => {
        const canvas = canvasRef.current

        if (canvas) {
            initializeCanvas().catch(e => {
                Sentry.captureException(e)
            })
        }
    }, [drawing, initializeCanvas])

    return (
        <div className="h-full w-full flex items-center justify-center">
            <canvas
                className="touch-none shadow rounded-xl overflow-hidden aspect-[2/3] max-h-full max-w-full"
                ref={canvasRef}
            >
            </canvas>
        </div>
    )
}
export default Canvas
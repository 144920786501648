const drawWithBucket = ({
  context,
  colour,
}: {
  context: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D;
  colour: string;
}) => {
  context.fillStyle = colour;
  context.rect(0, 0, context.canvas.width, context.canvas.height);
  context.fill();
};

export default drawWithBucket;

import { ReactNode } from "react"
import Vibrator from "../utils/Vibrator"

const ImpactButton = (
    { type = "button",
        disabled = false,
        children,
        className,
        onClick,
        ...props
    }:
        {
            type?: "button" | "submit",
            disabled?: boolean,
            children: ReactNode,
            className?: string,
            onClick?: () => void
        }) => {

    const handleClick = () => {
        onClick?.()
        Vibrator.buttonPress()
    }

    return (
        <button type={type} disabled={disabled} onClick={handleClick} {...props} className={className}>
            {children}
        </button >
    )
}

export default ImpactButton
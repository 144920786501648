import { useEffect } from "react";

const useDisableLoupe = (element: HTMLElement | SVGElement | null) => {
  const handleTouchEvent = (event: Event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!element) {
      return;
    }

    element.addEventListener("touchstart", handleTouchEvent, {
      passive: false,
    });
    element.addEventListener("touchmove", handleTouchEvent, { passive: false });
    element.addEventListener("touchend", handleTouchEvent, { passive: false });
    return () => {
      element.removeEventListener("touchstart", handleTouchEvent);
      element.removeEventListener("touchmove", handleTouchEvent);
      element.removeEventListener("touchend", handleTouchEvent);
    };
  }, [element]);
};

export default useDisableLoupe;

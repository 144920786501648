import useOneFinger from "./useOneFinger";
import ToolType from "../types/ToolType";
import ToolResult from "../types/ToolResult";

import drawWithBrush from "../utils/tools/drawWithBrush";
import drawWithSpray from "../utils/tools/drawWithSpray";
import drawWithSplatter from "../utils/tools/drawWithSplatter";
import drawWithEraser from "../utils/tools/drawWithEraser";
import drawWithZig from "../utils/tools/drawWithZig";
import useTool from "./useTool";
import useBucket from "./useBucket";
import { useAtomValue } from "jotai";
import { brushTypeAtom, canvasAtom } from "../components/Editor/editorAtoms";

const useTools: ({
  onCanvasChange,
}: {
  onCanvasChange: () => void;
}) => void = ({ onCanvasChange }) => {
  const canvas = useAtomValue(canvasAtom);
  const brushType = useAtomValue(brushTypeAtom);

  const tools: Record<ToolType, ToolResult> = {
    [ToolType.Brush]: useTool({ drawer: drawWithBrush, onCanvasChange }),
    [ToolType.Bucket]: useBucket({ onCanvasChange }),
    [ToolType.Spray]: useTool({ drawer: drawWithSpray, onCanvasChange }),
    [ToolType.Splatter]: useTool({
      drawer: drawWithSplatter,
      onCanvasChange,
    }),
    [ToolType.Eraser]: useTool({ drawer: drawWithEraser, onCanvasChange }),
    [ToolType.Zig]: useTool({
      drawer: drawWithZig,
      onCanvasChange,
    }),
  };

  const tool = tools[brushType];

  useOneFinger({
    element: canvas,
    onGesture: tool.onGesture,
    onGestureEnd: tool.onGestureEnd,
    onGestureCancel: tool.onGestureCancel,
  });
};

export default useTools;

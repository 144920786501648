//https://colorkit.co/color-palette-generator/ff7334-ff8b59-ffa278-ffb895-ffcdb1-f6dbc5-e3e4cf-ceecda-b6f3e5-99faef/

const palettes = [
  {
    name: "Pastels",
    colours: [
      "#FFC0CB", // Pink
      "#87CEEB", // SkyBlue
      "#98FB98", // PaleGreen
      "#FFD700", // Gold
      "#FFA07A", // LightSalmon
      "#FF69B4", // HotPink
      "#D8BFD8", // Thistle
      "#FF6347", // Tomato
      "#20B2AA", // LightSeaGreen
    ],
  },
  {
    name: "Rainbow",
    colours: [
      "#FF0000",
      "#FF7F00",
      "#FFFF00",
      "#00FF00",
      "#0000FF",
      "#4B0082",
      "#9400D3",
      "#FF1493",
      "#FF69B4",
      "#FF4500",
      "#FFD700",
      "#32CD32",
      "#00FFFF",
      "#1E90FF",
      "#8A2BE2",
      "#800080",
      "#FF00FF",
      "#FF6347",
      "#FFA500",
      "#00FF7F",
    ],
  },
  {
    name: "Forest",
    colours: [
      "#0B3D02", // Dark Forest Green
      "#165B33", // Medium Forest Green
      "#1C7425", // Forest Green
      "#38755B", // Fern Green
      "#A3C1AD", // Light Moss Green
      "#7BB662", // Moss Green
      "#4A7C59", // Juniper Green
      "#6B8E23", // Olive Drab
      "#2C5234", // Dark Sage Green
      "#556B2F", // Dark Olive Green
    ],
  },
  {
    name: "Manga",
    colours: [
      "#FF0000",
      "#FF69B4",
      "#FFA500",
      "#FFFF00",
      "#00FF00",
      "#00FFFF",
      "#0000FF",
      "#800080",
      "#FF1493",
      "#7FFF00",
      "#FFD700",
      "#FF4500",
      "#8A2BE2",
      "#008080",
      "#FFC0CB",
      "#800000",
      "#808080",
      "#008000",
      "#000000",
      "#FFFFFF",
    ],
  },
  {
    // https://icolorpalette.com/color/budder-skin
    name: "Skin",
    colours: [
      "#fcead4",
      "#fbdab5",
      "#f9cb96",
      "#f8bc77",
      "#f6ac58",
      "#f49d38",
      "#f38e19",
      "#e17d0b",
      "#c26c09",
      "#a35b08",
      "#844906",
      "#643805",
      "#452703",
      "#261501",
      "#070400",
    ],
  },
  {
    name: "Autumn",
    colours: [
      "#E68B4C",
      "#C95B19",
      "#B86125",
      "#A84300",
      "#FF7F50",
      "#FF4500",
      "#E97451",
      "#D2691E",
      "#FFA07A",
      "#FF8C00",
      "#DAA520",
      "#FFD700",
      "#FFA500",
      "#8B4513",
      "#B8860B",
      "#CD853F",
      "#D2B48C",
      "#F4A460",
    ],
  },

  {
    name: "Winter",
    colours: [
      "#FFFFFF",
      "#F0F8FF",
      "#B0C4DE",
      "#ADD8E6",
      "#87CEEB",
      "#00BFFF",
      "#4682B4",
      "#6495ED",
      "#5F9EA0",
      "#7FFFD4",
      "#AFEEEE",
      "#00CED1",
      "#F0FFFF",
      "#E0FFFF",
      "#B0E0E6",
      "#87CEFA",
      "#1E90FF",
      "#4169E1",
    ],
  },
  {
    name: "Pastel Rainbow",
    colours: [
      "#ffadad",
      "#ffd6a5",
      "#fdffb6",
      "#caffbf",
      "#9bf6ff",
      "#a0c4ff",
      "#bdb2ff",
      "#ffc6ff",
    ],
  },
  {
    name: "Strange",
    colours: [
      "#4e929b",
      "#7d9993",
      "#a59f89",
      "#caa37e",
      "#eda66f",
      "#ffad75",
      "#ffba90",
      "#ffc6aa",
      "#ffd2c3",
      "#ffdddb",
    ],
  },
  {
    name: "Fun",
    colours: [
      "#f8c8dc",
      "#f9d3ce",
      "#faddc0",
      "#fbe8b0",
      "#fcf29e",
      "#e7eaa0",
      "#becfb2",
      "#96b4bf",
      "#6f98c7",
      "#4a79cd",
    ],
  },
  {
    name: "Sunset",
    colours: [
      "#ff7334",
      "#ff8b59",
      "#ffa278",
      "#ffb895",
      "#ffcdb1",
      "#f6dbc5",
      "#e3e4cf",
      "#ceecda",
      "#b6f3e5",
      "#99faef",
    ],
  },
];

export default palettes;

import { Share, Trophy } from "lucide-react";
import SideButton from "./SideButton";
import { useAtomValue, useSetAtom } from "jotai";
import { canvasAtom, colourChoiceAtom, drawingAtom, isSubmitToContestModalOpenAtom, recentColourChoicesAtom } from "./editorAtoms";

const RightButtons = () => {
    const canvas = useAtomValue(canvasAtom)
    const drawing = useAtomValue(drawingAtom)
    const recentColourChoices = useAtomValue(recentColourChoicesAtom)
    const setColourChoice = useSetAtom(colourChoiceAtom)
    const setIsSubmitToContestModalOpen = useSetAtom(isSubmitToContestModalOpenAtom)

    const handleShare = async () => {
        const dataUrl = canvas!.toDataURL();
        const blob = await (await fetch(dataUrl)).blob();
        const filesArray: File[] = [
            new File([blob], drawing!.path, { type: blob.type, lastModified: new Date().getTime() })
        ];
        const shareData = {
            files: filesArray,
        };

        try {
            await navigator.share(shareData as ShareData)
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return
            }
            throw e
        }
    }

    return (
        <>
            <div className="pointer-events-auto rounded-full shadow overflow-hidden">
                <SideButton onClick={() => handleShare()}>
                    <Share size="1em" />
                </SideButton>
            </div>

            <div className="pointer-events-auto rounded-full shadow overflow-hidden">
                <SideButton style="contest" onClick={() => setIsSubmitToContestModalOpen(true)}>
                    <Trophy size="1em" />
                </SideButton>
            </div>

            <div className="grow flex items-center">
                <div className="pointer-events-auto rounded-full shadow overflow-hidden">
                    {recentColourChoices.length > 0 && (
                        <div className="max-h-48 overflow-hidden overflow-y-scroll no-scrollbar">
                            {recentColourChoices.map((recentColourChoice, index) => (
                                <SideButton key={index} onClick={() => {
                                    setColourChoice(
                                        {
                                            colour: recentColourChoice.colour,
                                            colourModifier: recentColourChoice.colourModifier,
                                            palette: recentColourChoice.palette
                                        }
                                    )
                                }}>
                                    <div className="w-full h-full rounded-full" style={{ backgroundColor: recentColourChoice.colourModifier }}></div>
                                </SideButton>
                            ))}
                        </div>
                    )}

                    {recentColourChoices.length > 0 || (
                        <div className="">
                            <div className="flex items-center justify-center w-10 h-48 bg-gray-50 dark:bg-gray-600">
                                <div className="text-nowrap font-bold inline-block -rotate-90 uppercase text-sm text-gray-400 dark:text-gray-50">
                                    color history
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )

}
export default RightButtons;